@keyframes moveText {
    0% {
      transform: translateX(100vw);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .moving-text {
    display: inline-block;
    white-space: nowrap;
    animation: moveText 10s linear infinite;
  }

  .swal-custom-title{
    font-size: 0.98rem;
    text-align: 'center';
  }

  .phoneMail{
    color:blue!important;
  }

  
